<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <template>
        <v-chip
          class="mb-2"
          :color="(documentObj.documentFiles_count > 0) ? 'green' : 'grey'"
          text-color="white"
          v-bind="attrs"
          @dblclick="goToDocumentDetail(documentObj.id)"
          v-on="on"
        >
          <v-avatar
            left
            :class="(documentObj.documentFiles_count > 0) ? 'green darken-4' : 'grey darken-4'"
          >
            {{ documentObj.files_count }}
          </v-avatar>
          Gestisci
        </v-chip>
      </template>
    </template>
    <v-list>
      <v-list-item
        link
        :to="{name:'companyDocumentFiles',params:{did:documentObj.id}}"
      >
        <v-list-item-title>
          <v-icon
            small
            left
          >
            mdi-folder-open-outline
          </v-icon>
          Apri
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="loggedUser.role === 'professional'"
        link
        @click="clickUpload"
      >
        <v-list-item-title>
          <v-icon
            small
            left
          >
            mdi-upload
          </v-icon>
          Carica file
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CompanyDocumentsActionMenu',
  props: {
    documentObj: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['loggedUser']),
  },
  methods: {
    clickUpload() {
      this.$emit('clickUpload')
    },
    goToDocumentDetail(did) {
      this.$router.push({
        name: 'companyDocumentFiles',
        params: {
          cid: this.documentObj.company_id,
          did,
        },
      })
    },
  },
}
</script>
