import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mt-3 d-flex flex-column",style:(_vm.urgencyCard(_vm.documentObj.priority)),attrs:{"height":"100%"}},[_c(VCardTitle,[_c('h5',[_vm._v(_vm._s(_vm.documentObj.title))]),_c(VSpacer),(_vm.loggedUser.role === 'professional')?_c('company-documents-menu',{on:{"clickEdit":function($event){_vm.isShow.edit = true},"clickDelete":function($event){_vm.isShow.delete = true}}}):_vm._e()],1),_c(VCardText,[_c(VRow,[_c(VCol,[_c('div',{domProps:{"innerHTML":_vm._s(_vm.documentObj.description)}})])],1)],1),_c(VSpacer),_c(VCardActions,[(_vm.documentObj.flag_auto_import === 'yes')?_c('div',[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"ma-2 white--text success"},'v-chip',attrs,false),on),[_vm._v(" Importazione automatica ")])]}}],null,false,2413232366)},[_vm._v(" Il documento verrà importato automaticamente in ogni nuova pratica ")])],1):_vm._e(),_c(VSpacer),_c('company-documents-action-menu',{attrs:{"document-obj":_vm.documentObj},on:{"clickUpload":function($event){_vm.isShow.upload = true}}})],1),_c('edit-company-document',{attrs:{"is-show":_vm.isShow.edit,"document-data":_vm.documentObj},on:{"close":function($event){_vm.isShow.edit=false},"reload-data":_vm.getData}}),_c('destroy-company-document',{attrs:{"is-show":_vm.isShow.delete,"document-data":_vm.documentObj},on:{"close":function($event){_vm.isShow.delete=false},"reload-data":_vm.getData}}),_c('upload-file',{attrs:{"is-show":_vm.isShow.upload,"upload-url":_vm.uploadUrl},on:{"close":function($event){_vm.isShow.upload=false},"reload-data":_vm.getData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }