<template>
  <div>
    <company-documents-header
      v-if="loggedUser.role === 'professional'"
      @clickCreate="isShow.create = true"
    />

    <company-card
      section-name="Documenti condivisi"
    />

    <v-divider></v-divider>

    <v-row>
      <v-col
        v-for="(doc,idx) in companyDocumentsList"
        :key="idx"
        cols="12"
        sm="12"
        md="4"
      >
        <company-documents-card
          :document-obj="doc"
        />
      </v-col>
    </v-row>
    <create-company-document
      :is-show="isShow.create"
      @close="isShow.create=false"
      @reload-data="getData"
    ></create-company-document>
  </div>
</template>

<script>

import CompanyDocumentsHeader from '@/components/section-headers/company-documents-header.vue'
import CompanyCard from '@/components/cards/company-card.vue'
import CompanyDocumentsCard from '@/components/cards/company-documents-card.vue'
import CreateCompanyDocument from '@/components/modals/create-company-document.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'CompanyDocuments',
  components: {
    CompanyDocumentsCard,
    CompanyDocumentsHeader,
    CompanyCard,
    CreateCompanyDocument,
  },
  data() {
    return {
      isShow: {
        create: false,
        edit: false,
        destroy: false,
      },
      upload_url: '',
      edit_doc_obj: {},
      breadCrumb: [
        {
          text: 'Dashboard',
          to: '/dashboard',
        },
        {
          text: 'Documenti condivisi',
          to: '/company-documents',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['loggedUser', 'loggedUserCompany', 'companyDocumentsList']),
  },
  mounted() {
    this.$store.dispatch('setBreadCrumbLevels', this.breadCrumb)
  },
  methods: {
    async getData() {
      await this.$store.dispatch('getCompanyDocuments')
    },
    setModalDocument(docId) {
      this.upload_url = `${this.$apiUrl}company-documents/${this.loggedUserCompany.id}/documents/${docId}`
    },
    async setModalDocumentObj(docObj) {
      this.edit_doc_obj = await docObj
    },

    // TODO DELETE THIS
    urgencyCard(priority) {
      if (priority === 'high') {
        return 'border-top:10px solid #ffccbc;'
      } if (priority === 'low') {
        return 'border-top:10px solid #c8e6c9;'
      }

      return 'border-top:10px solid #c5cae9;'
    },
  },
}
</script>

<style scoped>

</style>
