<template>
  <v-card
    class="mt-3 d-flex flex-column"
    :style="urgencyCard(documentObj.priority)"
    height="100%"
  >
    <v-card-title>
      <h5>{{ documentObj.title }}</h5>

      <v-spacer></v-spacer>

      <company-documents-menu
        v-if="loggedUser.role === 'professional'"
        @clickEdit="isShow.edit = true"
        @clickDelete="isShow.delete = true"
      />
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <div v-html="documentObj.description"></div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-spacer />
    <v-card-actions>
      <div
        v-if="documentObj.flag_auto_import === 'yes'"
      >
        <v-tooltip
          top
        >
          <template
            v-slot:activator="{ on, attrs }"
          >
            <v-chip
              v-bind="attrs"
              class="ma-2 white--text success"
              v-on="on"
            >
              Importazione automatica
            </v-chip>
          </template>
          Il documento verrà importato automaticamente in ogni nuova pratica
        </v-tooltip>
      </div>
      <v-spacer></v-spacer>

      <company-documents-action-menu
        :document-obj="documentObj"
        @clickUpload="isShow.upload = true"
      />
    </v-card-actions>
    <edit-company-document
      :is-show="isShow.edit"
      :document-data="documentObj"
      @close="isShow.edit=false"
      @reload-data="getData"
    />
    <destroy-company-document
      :is-show="isShow.delete"
      :document-data="documentObj"
      @close="isShow.delete=false"
      @reload-data="getData"
    />
    <upload-file
      :is-show="isShow.upload"
      :upload-url="uploadUrl"
      @close="isShow.upload=false"
      @reload-data="getData"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import EditCompanyDocument from '@/components/modals/edit-company-document.vue'
import DestroyCompanyDocument from '@/components/modals/delete-company-document.vue'
import UploadFile from '@/components/modals/upload-file.vue'
import CompanyDocumentsMenu from '@/components/menus/company-documents-menu.vue'
import CompanyDocumentsActionMenu from '@/components/menus/company-documents-action-menu.vue'

export default {
  name: 'CompanyDocumentsCard',
  components: {
    CompanyDocumentsActionMenu,
    CompanyDocumentsMenu,
    EditCompanyDocument,
    DestroyCompanyDocument,
    UploadFile,
  },
  props: {
    documentObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShow: {
        upload: false,
        edit: false,
        delete: false,
      },
    }
  },
  computed: {
    ...mapGetters(['loggedUser', 'loggedUserCompany']),
    uploadUrl() {
      return `${this.$apiUrl}company-documents/${this.loggedUserCompany.id}/documents/${this.documentObj.id}`
    },
  },
  methods: {
    getData() {
      this.$store.dispatch('getCompanyDocuments')
    },
    urgencyCard(priority) {
      if (priority === 'high') {
        return 'border-top:10px solid #ffccbc;'
      } if (priority === 'low') {
        return 'border-top:10px solid #c8e6c9;'
      }

      return 'border-top:10px solid #c5cae9;'
    },
  },
}

</script>
